import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/header/HeaderFour";
import About from "../../container/about-us/AboutArchitecture";
import CallToActionOne from "../../container/call-to-action/homes/CallToActionOne";
import CallToAction from "../../container/call-to-action/homes/CallToActionThree";
import CallToActionTwo from "../../container/call-to-action/homes/CallToActionTwo";
import Footer from "../../container/footer/FooterFive";
import SliderArchitecture from "../../container/slider/SliderArchitecture";
import Testimonial from "../../container/testimonial/homes/TestimonialFour";
import LayoutDefault from "../../layouts/LayoutDefault";

const HomeArchitecture = () => {
  const [message, setMessage] = useState("");

  const extractText = (inputString) => {
    const pattern =
      /\\u003cHier den Text eintragen\\u003e(.*?)\\u003cHier den Text eintragen\/\\u003e/gs;
    const matches = [...inputString.matchAll(pattern)];
    return matches.map((match) => match[1]);
  };

  useEffect(() => {
    const URL =
      "https://docs.google.com/document/d/1yzDVpYusc_HuwW0eYIh7d1ew5NOmzhiJECQA4dOD8kM/edit?usp=sharing";
    fetch(URL)
      .then((res) => res.text())
      .then((text) => {
        const result = extractText(text)[0];
        setMessage(result === "Leer" ? "" : result);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>{"KAWA Druck"}</title>
      </Helmet>
      <LayoutDefault
        className="template-color-4 template-font-1 bg_color--1"
        revealFooter={true}
      >
        <Header />
        <SliderArchitecture message={message} />
        <Testimonial />

        <CallToActionOne />

        <CallToActionTwo />

        <About />

        <CallToAction bgColor={"#aecc36"} />
      </LayoutDefault>
      <Footer />
    </Fragment>
  );
};

export default HomeArchitecture;
