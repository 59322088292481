import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";

const AboutArchitecture = () => {
  useEffect(() => {
    setTouchDevice("ontouchstart" in document.documentElement);
  }, []);
  const [touchDevice, setTouchDevice] = useState(false);
  return (
    <div
      id="Weiterverarbeitung"
      className="brook-story-area ptb--100 ptb-md--80 ptb-sm--60  bg_color--1"
    >
      <Row>
        <Col lg={6}>
          <div className="pl--380 pr--80 pl_lp--80 pl_lg--50 pr_lg--20 pl_md--50 pr_md--20 pl_sm--30 pr_sm--20">
            <div className="architecture-story wow move-up">
              <div className="bk-separator--25" />

              <h2 className="heading heading-h2 line-height-1-25">
                Wir machen für Sie <span className="kawaColor">ALLES</span>{" "}
                möglich!
              </h2>

              <div className="bk-separator--45" />

              <div className="bk-separator--20" />
              <p className="bk_pra">
                <h4 className="heading heading-h4" style={{ color: "#000000" }}>
                  schneiden, rillen, falzen, heften, stanzen, perforieren,
                  binden, bohren, prägen, lackieren, kaschieren, cellophanieren,
                  konfektionieren, kuvertieren, versenden, etc…
                  <br /> <br />
                  <span className="bold">
                    {touchDevice ? (
                      <>
                        <div className="bk-separator--20" />
                      </>
                    ) : (
                      <> </>
                    )}
                    Durch verschiedene Druckveredelungen kann Ihr Druckobjekt
                    Emotionen wecken – sprechen{" "}
                    <span className="kawaColor">Sie</span> uns an.{" "}
                  </span>
                </h4>
              </p>
              <div className="bk-separator--55" />
            </div>
          </div>
        </Col>

        <Col lg={6}>
          <div className="mt_md--40 mt_sm--40">
            <div className="architecture-story">
              <div className="thumb wow move-up">
                <img
                  src={"./weiterverarbeitung.jpg"}
                  alt="weiterverarbeitung"
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AboutArchitecture;
