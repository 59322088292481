import React from "react";
import { Link } from "react-router-dom";

const Logo = ({ logo }) => {
  return (
    <div className="logo">
      <Link to={process.env.PUBLIC_URL + "/"}>
        <h4 className="kawaColor ethnoFont bitUnder">Kawa Druck</h4>
      </Link>
    </div>
  );
};

export default Logo;
