import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CallToActionContent from "../../../components/call-to-action/CallContent";

const CallToActionOne = ({ bgColor, bgImage }) => {
  return (
    <div id="Druck">
      <div
        className="brook-call-to-action ptb--100 "
        style={{ backgroundColor: "#001029" }}
      >
        <Container>
          <Row className="align-items-center">
            <Col xs={12} md={8} className={"wow move-up"}>
              <CallToActionContent textColor="#000000">
                <span className="kawaColor">Impressum</span>
              </CallToActionContent>
            </Col>
            <Col xs={12} md={8} className={"wow move-up mt--60"}>
              <div className="call-content text-center text-md-left">
                <h4 className="heading heading-h5" style={{ color: "#fff" }}>
                  Andrea Walouch
                  <br /> Drucktechnikerin
                  <br /> Mühlwengert 7<br /> 71711 Murr
                  <br />
                  <br />
                  Telefon: 07144 - 2 54 32
                  <br /> Telefax: 07144 - 2 50 02
                  <br />
                  info@kawa-druck.de
                  <br />
                  <br /> Steuernummer: 71271/56384
                  <br />
                  <br />
                  Datenschutzbeauftragter: Joachim Walouch <br />
                  <br />
                  <br />
                  Quellenangaben für die verwendeten Bilder und Grafiken:
                  woelfle webdesign, logo_text.png, http://www.123rf.com: Achim
                  Prill, Tatiana Popova, Sergey Goruppa, Stephan Bormotov
                  Layout, Konfiguration: woelfle webdesign
                  <br />
                  <br />
                  Haftungsausschluss:
                  <br />
                  <br /> <span className="bold">Haftung für Inhalte</span>
                  <br /> Die Inhalte unserer Seiten wurden mit größter Sorgfalt
                  erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität
                  der Inhalte können wir jedoch keine Gewähr übernehmen. Als
                  Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
                  Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
                  verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
                  Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
                  gespeicherte fremde Informationen zu überwachen oder nach
                  Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
                  hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
                  Nutzung von Informationen nach den allgemeinen Gesetzen
                  bleiben hiervon unberührt. Eine diesbezügliche Haftung ist
                  jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
                  Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
                  Rechtsverletzungen werden wir diese Inhalte umgehend
                  entfernen.
                  <br />
                  <br /> <span className="bold">Haftung für Links</span>
                  <br /> Unser Angebot enthält Links zu externen Webseiten
                  Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb
                  können wir für diese fremden Inhalte auch keine Gewähr
                  übernehmen. Für die Inhalte der verlinkten Seiten ist stets
                  der jeweilige Anbieter oder Betreiber der Seiten
                  verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
                  Verlinkung auf mögliche Rechtsverstöße überprüft.
                  Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
                  erkennbar. Eine permanente inhaltliche Kontrolle der
                  verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
                  Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
                  Rechtsverletzungen werden wir derartige Links umgehend
                  entfernen.
                  <br />
                  <br /> <span className="bold">Urheberrecht</span>
                  <br /> Die durch die Seitenbetreiber erstellten Inhalte und
                  Werke auf diesen Seiten unterliegen dem deutschen
                  Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung
                  und jede Art der Verwertung außerhalb der Grenzen des
                  Urheberrechtes bedürfen der schriftlichen Zustimmung des
                  jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser
                  Seite sind nur für den privaten, nicht kommerziellen Gebrauch
                  gestattet. Soweit die Inhalte auf dieser Seite nicht vom
                  Betreiber erstellt wurden, werden die Urheberrechte Dritter
                  beachtet. Insbesondere werden Inhalte Dritter als solche
                  gekennzeichnet. Sollten Sie trotzdem auf eine
                  Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
                  entsprechenden Hinweis. Bei Bekanntwerden von
                  Rechtsverletzungen werden wir derartige Inhalte umgehend
                  entfernen.
                </h4>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default CallToActionOne;
