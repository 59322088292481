import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";

const AboutArchitecture = () => {
  useEffect(() => {
    setTouchDevice("ontouchstart" in document.documentElement);
  }, []);
  const [touchDevice, setTouchDevice] = useState(false);
  return (
    <div
      id="Druck"
      className="brook-story-area ptb--100 ptb-md--80 ptb-sm--60  bg_color--1"
    >
      <Row>
        <Col lg={6}>
          <div className="pl--380 pr--80 pl_lp--80 pl_lg--50 pr_lg--20 pl_md--50 pr_md--20 pl_sm--30 pr_sm--20">
            <div className="architecture-story wow move-up">
              <div className="bk-separator--25" />

              <h2 className="heading heading-h2 line-height-1-25">
                Wir nehmen <span className="kawaColor">Ihnen</span> den Druck ab
              </h2>

              <div className="bk-separator--45" />

              <div className="bk-separator--20" />
              <p className="bk_pra">
                <h4 className="heading heading-h4" style={{ color: "#000000" }}>
                  Geschäftsausstattung, Broschüren, Flyer,
                  Bedienungsanleitungen, personalisierte Mailings,
                  Werbedrucksachen, Plakate, SB-Reiter, Etiketten, Einladungen,
                  Kalender, Blocks, Ordner, Endlossätze, Postkarten und vieles
                  mehr. <br /> <br />
                  {touchDevice ? (
                    <>
                      {" "}
                      <div className="bk-separator--20" />{" "}
                    </>
                  ) : (
                    <> </>
                  )}{" "}
                  Der Digitalduck ermöglicht es uns auch kleine Auflagen
                  kostengünstig anbieten zu können!
                </h4>
              </p>

              <div className="bk-separator--55" />
            </div>
          </div>
        </Col>

        <Col lg={6}>
          <div className="mt_md--40 mt_sm--40">
            <div className="architecture-story">
              <div className="thumb wow move-up">
                <img src={"./Druck.jpg"} alt="Druck" />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AboutArchitecture;
