import React from "react";
import PropTypes from "prop-types";

const TestimonialItemFour = ({ data }) => {
  return (
    <div className="testimonial-style--3 text-center">
      <div className="content">
        <h2 className="whiteColor">
          Wir machen den Punkt, Satz und holen den Erfolg für{" "}
          <span className="kawaColor">Sie</span>
        </h2>
      </div>
      <div className="content mt--40">
        <h4 className="heading heading-h4 text-white line-height-1-88">
          Von der Vorstellung zum Entwurf zur fertigen Druckdatei. <br />
          <br />
          Satzgestaltung, Satzänderungen, Scans, digitale Bildbearbeitung,
          farbverbindliche Proofs und vieles mehr... <br />
          <br /> Fordern Sie uns heraus!
        </h4>
      </div>
    </div>
  );
};

TestimonialItemFour.propTypes = {
  data: PropTypes.object,
};

export default TestimonialItemFour;
