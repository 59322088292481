import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "../../components/button";

const Error404 = () => {
  return (
    <div className="error-not-found">
      <Container>
        <Row>
          <Col xs={12}>
            <div className="error-inner text-center">
              <h3 className="heading heading-h3 text-white">
                Seite nicht gefunden.
              </h3>
              <div className="error-text mt--20">
                <p className="text-white">
                  Die aufgerufene Seite konnte leider nicht gefunden werden. Die
                  Seite wurde entweder verschoben oder existiert nicht mehr.
                </p>

                <div className="error-button-group mt--40">
                  <a
                    href="/"
                    className={`brook-btn  btn-sd-size btn-rounded space-between kawaBG`}
                    onClick={() => window.history.back()}
                  >
                    Zurück
                  </a>

                  <Button
                    type="link"
                    btnText="Startseite"
                    btnLink="/"
                    btnStyle="rounded"
                    size="sd"
                    color="white"
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Error404;
