import React from "react";
import navbarData from "../../../../data/navbar";

const NavbarItem = () => {
  return navbarData.map((navbar) => (
    <li
      key={navbar.id}
      className={`label-1 ${
        navbar.megaMenu || navbar.subMenu ? "with--drop" : ""
      } ${navbar.megaMenu ? "slide--megamenu" : ""} ${
        navbar.subMenu ? "slide-dropdown" : ""
      }`}
    >
      <a href={`${navbar.link}`}>
        <span>{navbar.title}</span>
      </a>
    </li>
  ));
};

export default NavbarItem;
