import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import WOW from "wowjs";
import ScrollToTop from "./helper/scrollToTop";
import Datenschutz from "./pages/homes/Datenschutz";
import HomeArchitecture from "./pages/homes/HomeArchitecture";
import Impressum from "./pages/homes/Impressum";
import Error from "./pages/others/Error";

const App = () => {
  useEffect(() => {
    new WOW.WOW().init();
  }, []);
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route exact path={"/"} component={HomeArchitecture} />
          <Route path={"/Impressum"} component={Impressum} />
          <Route path={"/Datenschutz"} component={Datenschutz} />
          <Route exact component={Error} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default App;
