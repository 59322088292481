import React from "react";

const MapOne = (props) => {
  return (
    <div className="google-map-area  bg_color--1 section text-center">
      <div
        className="position-relative"
        style={{ height: "200px", width: "300px" }}
      >
        <iframe
          title="OpenMap"
          width="400"
          height="200"
          frameborder="0"
          src="https://www.openstreetmap.org/export/embed.html?bbox=9.256123602390291%2C48.95757679860974%2C9.2583766579628%2C48.95886949223062&amp;layer=mapnik&amp;marker=48.958223658694074%2C9.257250905502588"
          style={{ border: "1px solid black" }}
        ></iframe>
        <br />
        <small>
          <a href="https://www.openstreetmap.org/?mlat=48.95822&amp;mlon=9.25692#map=19/48.95822/9.25692">
            Größere Karte anzeigen
          </a>
        </small>
      </div>
    </div>
  );
};

export default MapOne;
