import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Widget from "../../components/widget";
import MapOne from "../maps/elements/MapOne";

const FooterFive = () => {
  useEffect(() => {
    setTouchDevice("ontouchstart" in document.documentElement);
  }, []);
  const [touchDevice, setTouchDevice] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }

      window.addEventListener("resize", handleResize);

      handleResize();

      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);
  return (
    <>
      {windowSize.width <= 350 ? (
        <>
          {" "}
          <footer
            id="page-footer-wrapper"
            className="unFixed page-footer-wrapper fixed-footer bg_color--11 pl--150 pr--150 pl_lg--30 pr_lg--30 pl_md--30 pr_md--30 pl_sm--5 pr_sm--5"
          >
            <div className="bk-footer-inner pt--150 pb--30 pt_sm--100">
              <Container>
                <Row>
                  <Col xs={12} sm={6} lg={4}>
                    <Widget wrapClasses="text-var--2" title={null}>
                      <h4 className="kawaColor ethnoFont">Kawa Druck</h4>
                      <div className="footer-inner">
                        <p>
                          Die beste Art zu kommunizieren, ist die direkte. Wir
                          freuen uns auf Ihren Besuch oder Anruf! Natürlich
                          kommen wir auch gerne zu Ihnen.
                          <br />
                          <br /> Da wir im Auftrag unserer Kunden oft unterwegs
                          sind, bitten wir um vorherige Terminabsprache.
                        </p>
                      </div>
                    </Widget>
                  </Col>

                  <Col xs={12} sm={6} lg={3} className="mt_md--40 mt_sm--40">
                    <Widget
                      wrapClasses="text-var--2 menu--about"
                      title="Kontakt"
                    >
                      <div className="footer-address">
                        <div className="bk-hover">
                          <p>
                            Andrea Walouch <br /> Mühlwengert 7 <br /> 71711
                            Murr <br /> Telefon: 07144 - 2 54 32 <br />
                            Telefax: 07144 - 2 50 02
                          </p>
                          <p>
                            <a href={"mailto:info@kawa-druck.de"}>
                              info@kawa-druck.de
                            </a>
                          </p>
                        </div>
                      </div>
                    </Widget>
                  </Col>

                  <Col xs={12} sm={6} lg={3} className="mt_md--40 mt_sm--40">
                    <Widget
                      wrapClasses="text-var--2 menu--about"
                      title="Standort"
                    >
                      <MapOne />
                    </Widget>
                  </Col>
                </Row>
              </Container>
            </div>

            <div className="copyright ptb--50 text-var-2">
              <Container>
                <Row className="align-items-center">
                  <Col xs={12} md={6}>
                    <div className="copyright-left text-md-left text-center">
                      <ul className="bk-copyright-menu d-flex bk-hover justify-content-center justify-content-md-start flex-wrap flex-sm-nowrap">
                        <li>
                          <Link to={"/Impressum"}>Impressum</Link>
                        </li>
                        <li>
                          <Link to={"/Datenschutz"}>Datenschutz</Link>
                        </li>
                      </ul>
                    </div>
                  </Col>

                  <Col xs={12} md={6}>
                    <div className="copyright-right text-md-right text-center">
                      <p>
                        Andrea Walouch, Mühlwengert 7,
                        {touchDevice ? (
                          <>
                            <br />
                          </>
                        ) : (
                          <> </>
                        )}
                        Tel.: 07144-25432, info@kawa-druck.de
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </footer>{" "}
        </>
      ) : (
        <>
          {" "}
          <footer
            id="page-footer-wrapper"
            className="page-footer-wrapper fixed-footer bg_color--11 pl--150 pr--150 pl_lg--30 pr_lg--30 pl_md--30 pr_md--30 pl_sm--5 pr_sm--5"
          >
            <div className="bk-footer-inner pt--150 pb--30 pt_sm--100">
              <Container>
                <Row>
                  <Col xs={12} sm={6} lg={4}>
                    <Widget wrapClasses="text-var--2" title={null}>
                      <h4 className="kawaColor ethnoFont">Kawa Druck</h4>
                      <div className="footer-inner">
                        <p>
                          Die beste Art zu kommunizieren, ist die direkte. Wir
                          freuen uns auf Ihren Besuch oder Anruf! Natürlich
                          kommen wir auch gerne zu Ihnen.
                          <br />
                          <br /> Da wir im Auftrag unserer Kunden oft unterwegs
                          sind, bitten wir um vorherige Terminabsprache.
                        </p>
                      </div>
                    </Widget>
                  </Col>

                  <Col xs={12} sm={6} lg={3} className="mt_md--40 mt_sm--40">
                    <Widget
                      wrapClasses="text-var--2 menu--about"
                      title="Kontakt"
                    >
                      <div className="footer-address">
                        <div className="bk-hover">
                          <p>
                            Andrea Walouch <br /> Mühlwengert 7 <br /> 71711
                            Murr <br /> Telefon: 07144 - 2 54 32 <br />
                            Telefax: 07144 - 2 50 02
                          </p>
                          <p>
                            <a href={"mailto:info@kawa-druck.de"}>
                              info@kawa-druck.de
                            </a>
                          </p>
                        </div>
                      </div>
                    </Widget>
                  </Col>

                  <Col xs={12} sm={6} lg={3} className="mt_md--40 mt_sm--40">
                    <Widget
                      wrapClasses="text-var--2 menu--about"
                      title="Standort"
                    >
                      <MapOne />
                    </Widget>
                  </Col>
                </Row>
              </Container>
            </div>

            <div className="copyright ptb--50 text-var-2">
              <Container>
                <Row className="align-items-center">
                  <Col xs={12} md={6}>
                    <div className="copyright-left text-md-left text-center">
                      <ul className="bk-copyright-menu d-flex bk-hover justify-content-center justify-content-md-start flex-wrap flex-sm-nowrap">
                        <li>
                          <Link to={"/Impressum"}>Impressum</Link>
                        </li>
                        <li>
                          <Link to={"/Datenschutz"}>Datenschutz</Link>
                        </li>
                      </ul>
                    </div>
                  </Col>

                  <Col xs={12} md={6}>
                    <div className="copyright-right text-md-right text-center">
                      <p>
                        Andrea Walouch, Mühlwengert 7,
                        {touchDevice ? (
                          <>
                            <br />
                          </>
                        ) : (
                          <> </>
                        )}
                        Tel.: 07144-25432, info@kawa-druck.de
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </footer>{" "}
        </>
      )}
    </>
  );
};

export default FooterFive;
