import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CallToActionContent from "../../../components/call-to-action/CallContent";

const CallToActionOne = ({ bgColor, bgImage }) => {
  return (
    <div id="Druck">
      <div
        className="brook-call-to-action ptb--100 "
        style={{ backgroundColor: "#001029" }}
      >
        <Container>
          <Row className="align-items-center">
            <Col xs={12} md={8} className={"wow move-up"}>
              <CallToActionContent textColor="#000000">
                <span className="kawaColor">Datenschutzerklärung</span>
              </CallToActionContent>
            </Col>
            <Col xs={12} md={8} className={"wow move-up mt--60"}>
              <div className="call-content text-center text-md-left">
                <h4 className="heading heading-h5" style={{ color: "#fff" }}>
                  <span className="bold">1. Datenschutz auf einen Blick</span>
                  <br />
                  <br /> <span className="bold">Allgemeine Hinweise</span>
                  <br /> Die folgenden Hinweise geben einen einfachen Überblick
                  darüber, was mit Ihren personenbezogenen Daten passiert, wenn
                  Sie unsere Website besuchen. Personenbezogene Daten sind alle
                  Daten, mit denen Sie persönlich identifiziert werden können.
                  Ausführliche Informationen zum Thema Datenschutz entnehmen Sie
                  unserer unter diesem Text aufgeführten Datenschutzerklärung.
                  <br />
                  <br />
                  <span className="bold">
                    Datenerfassung auf unserer Website
                  </span>
                  <br />{" "}
                  <span className="bold">
                    Wer ist verantwortlich für die Datenerfassung auf dieser
                    Website?
                  </span>
                  <br /> Die Datenverarbeitung auf dieser Website erfolgt durch
                  den Websitebetreiber. Dessen Kontaktdaten können Sie dem
                  Impressum dieser Website entnehmen.
                  <br />
                  <br />{" "}
                  <span className="bold">
                    Wie erfassen wir Ihre Daten?
                  </span>{" "}
                  Ihre Daten werden zum einen dadurch erhoben, dass Sie uns
                  diese mitteilen. Hierbei kann es sich z.B. um Daten handeln,
                  die Sie in ein Kontaktformular eingeben. Andere Daten werden
                  automatisch beim Besuch der Website durch unsere IT-Systeme
                  erfasst. Das sind vor allem technische Daten (z.B.
                  Internetbrowser, Betriebssystem oder Uhrzeit des
                  Seitenaufrufs). Die Erfassung dieser Daten erfolgt
                  automatisch, sobald Sie unsere Website betreten.
                  <br />
                  <br />{" "}
                  <span className="bold">Wofür nutzen wir Ihre Daten?</span>
                  <br /> Ein Teil der Daten wird erhoben, um eine fehlerfreie
                  Bereitstellung der Website zu gewährleisten. Andere Daten
                  können zur Analyse Ihres Nutzerverhaltens verwendet werden.{" "}
                  <br />
                  <br />
                  <span className="bold">
                    Welche Rechte haben Sie bezüglich Ihrer Daten?
                  </span>
                  <br /> Sie haben jederzeit das Recht unentgeltlich Auskunft
                  über Herkunft, Empfänger und Zweck Ihrer gespeicherten
                  personenbezogenen Daten zu erhalten. Sie haben außerdem ein
                  Recht, die Berichtigung, Sperrung oder Löschung dieser Daten
                  zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema
                  Datenschutz können Sie sich jederzeit unter der im Impressum
                  angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen
                  ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
                  <br />
                  <br />
                  <span className="bold">
                    2. Allgemeine Hinweise und Pflichtinformationen
                  </span>
                  <br />
                  <br /> <span className="bold">Datenschutz</span>
                  <br />
                  Die Betreiber dieser Seiten nehmen den Schutz Ihrer
                  persönlichen Daten sehr ernst. Wir behandeln Ihre
                  personenbezogenen Daten vertraulich und entsprechend der
                  gesetzlichen Datenschutzvorschriften sowie dieser
                  Datenschutzerklärung. Wenn Sie diese Website benutzen, werden
                  verschiedene personenbezogene Daten erhoben. Personenbezogene
                  Daten sind Daten, mit denen Sie persönlich identifiziert
                  werden können. Die vorliegende Datenschutzerklärung erläutert,
                  welche Daten wir erheben und wofür wir sie nutzen. Sie
                  erläutert auch, wie und zu welchem Zweck das geschieht. Wir
                  weisen darauf hin, dass die Datenübertragung im Internet (z.B.
                  bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen
                  kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
                  Dritte ist nicht möglich.
                  <br />
                  <br />{" "}
                  <span className="bold">
                    Widerruf Ihrer Einwilligung zur Datenverarbeitung
                  </span>
                  <br /> Viele Datenverarbeitungsvorgänge sind nur mit Ihrer
                  ausdrücklichen Einwilligung möglich. Sie können eine bereits
                  erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine
                  formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der
                  bis zum Widerruf erfolgten Datenverarbeitung bleibt vom
                  Widerruf unberührt.
                  <br />
                  <br />{" "}
                  <span className="bold">
                    Beschwerderecht bei der zuständigen Aufsichtsbehörde
                  </span>
                  <br /> Im Falle datenschutzrechtlicher Verstöße steht dem
                  Betroffenen ein Beschwerderecht bei der zuständigen
                  Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde in
                  datenschutzrechtlichen Fragen ist der
                  Landesdatenschutzbeauftragte des Bundeslandes, in dem unser
                  Unternehmen seinen Sitz hat. Eine Liste der
                  Datenschutzbeauftragten sowie deren Kontaktdaten können
                  folgendem Link entnommen werden:
                  https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.
                  <br />
                  <br />
                  <span className="bold">Recht auf Datenübertragbarkeit</span>
                  <br /> Sie haben das Recht, Daten, die wir auf Grundlage Ihrer
                  Einwilligung oder in Erfüllung eines Vertrags automatisiert
                  verarbeiten, an sich oder an einen Dritten in einem gängigen,
                  maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die
                  direkte Übertragung der Daten an einen anderen
                  Verantwortlichen verlangen, erfolgt dies nur, soweit es
                  technisch machbar ist.
                  <br />
                  <br />{" "}
                  <span className="bold">Auskunft, Sperrung, Löschung</span>
                  <br /> Sie haben im Rahmen der geltenden gesetzlichen
                  Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft
                  über Ihre gespeicherten personenbezogenen Daten, deren
                  Herkunft und Empfänger und den Zweck der Datenverarbeitung und
                  ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser
                  Daten. Hierzu sowie zu weiteren Fragen zum Thema
                  personenbezogene Daten können Sie sich jederzeit unter der im
                  Impressum angegebenen Adresse an uns wenden.
                  <br />
                  <br />{" "}
                  <span className="bold">Widerspruch gegen Werbe-Mails</span>
                  <br /> Der Nutzung von im Rahmen der Impressumspflicht
                  veröffentlichten Kontaktdaten zur Übersendung von nicht
                  ausdrücklich angeforderter Werbung und Informationsmaterialien
                  wird hiermit widersprochen. Die Betreiber der Seiten behalten
                  sich ausdrücklich rechtliche Schritte im Falle der
                  unverlangten Zusendung von Werbeinformationen, etwa durch
                  Spam-E-Mails, vor.
                  <br />
                  <br />{" "}
                  <span className="bold">
                    Hinweis zur verantwortlichen Stelle
                  </span>
                  <br /> Die verantwortliche Stelle für die Datenverarbeitung
                  auf dieser Website ist KAWA Druck, Joachim Walouch,
                  Mühlwengert 7, 71711 Murr, Telefon: 07144-25432, eMail:
                  info@kawa-druck.de. Verantwortliche Stelle ist die natürliche
                  oder juristische Person, die allein oder gemeinsam mit anderen
                  über die Zwecke und Mittel der Verarbeitung von
                  personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. Ä.)
                  entscheidet.
                  <br />
                  <br />{" "}
                  <span className="bold">
                    3. Datenerfassung auf unserer Website
                  </span>
                  <br />
                  <br /> <span className="bold">Server-Log-Dateien</span>
                  <br /> Der Provider der Seiten erhebt und speichert
                  automatisch Informationen in so genannten Server-Log-Dateien,
                  die Ihr Browser automatisch an uns übermittelt. Dies sind:
                  <br />
                  <br /> -Browsertyp und Browserversion
                  <br />
                  -verwendetes Betriebssystem
                  <br /> -Referrer URL
                  <br /> -Hostname des zugreifenden Rechners
                  <br /> -Uhrzeit der Serveranfrage
                  <br /> -IP-Adresse
                  <br />
                  <br />
                  Eine Zusammenführung dieser Daten mit anderen Datenquellen
                  wird nicht vorgenommen. Grundlage für die Datenverarbeitung
                  ist Art. 6 Abs. 1 lit. f DSGVO, der die Verarbeitung von Daten
                  zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen
                  gestattet.
                  <br />
                  <br />{" "}
                  <span className="bold">
                    Verarbeiten von Daten (Kunden- und Vertragsdaten)
                  </span>
                  <br />
                  Wir erheben, verarbeiten und nutzen personenbezogene Daten
                  nur, soweit sie für die Begründung, inhaltliche Ausgestaltung
                  oder Änderung des Rechtsverhältnisses erforderlich sind
                  (Bestandsdaten). Dies erfolgt auf Grundlage von Art. 6 Abs. 1
                  lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung
                  eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
                  Personenbezogene Daten über die Inanspruchnahme unserer
                  Internetseiten (Nutzungsdaten) erheben, verarbeiten und nutzen
                  wir nur, soweit dies erforderlich ist, um dem Nutzer die
                  Inanspruchnahme des Dienstes zu ermöglichen oder abzurechnen.
                  Die erhobenen Kundendaten werden nach Abschluss des Auftrags
                  oder Beendigung der Geschäftsbeziehung gelöscht. Gesetzliche
                  Aufbewahrungsfristen bleiben unberührt. <br /> <br />{" "}
                  <span className="bold">
                    Anfrage per E-Mail, Telefon oder Telefax{" "}
                  </span>
                  <br /> Wenn Sie uns per E-Mail, Telefon oder Telefax
                  kontaktieren, wird Ihre Anfrage inklusive aller daraus
                  hervorgehenden personenbezogenen Daten (Name, Anfrage) zum
                  Zwecke der Bearbeitung Ihres Anliegens 8 / 35 bei uns
                  gespeichert und verarbeitet. Diese Daten geben wir nicht ohne
                  Ihre Einwilligung weiter. Die Verarbeitung dieser Daten
                  erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern
                  Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt
                  oder zur Durchführung vorvertraglicher Maßnahmen erforderlich
                  ist. In allen übrigen Fällen beruht die Verarbeitung auf Ihrer
                  Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) und / oder auf
                  unseren berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO),
                  da wir ein berechtigtes Interesse an der effektiven
                  Bearbeitung der an uns gerichteten Anfragen haben. Die von
                  Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben
                  bei uns, bis Sie uns zur Löschung auffordern, Ihre
                  Einwilligung zur Speicherung widerrufen oder der Zweck für die
                  Datenspeicherung entfällt (z. B. nach abgeschlossener
                  Bearbeitung Ihres Anliegens). Zwingende gesetzliche
                  Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen –
                  bleiben unberührt.
                  <br />
                  <br /> <span className="bold">4. Plugins und Tools</span>
                  <br />
                  <br /> <span className="bold">Google Web Fonts</span>
                  <br /> Diese Seite nutzt zur einheitlichen Darstellung von
                  Schriftarten so genannte Web Fonts, die von Google
                  bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr
                  Browser die benötigten Web Fonts in ihren Browsercache, um
                  Texte und Schriftarten korrekt anzuzeigen. Zu diesem Zweck
                  muss der von Ihnen verwendete Browser Verbindung zu den
                  Servern von Google aufnehmen. Hierdurch erlangt Google
                  Kenntnis darüber, dass über Ihre IP-Adresse unsere Website
                  aufgerufen wurde. Die Nutzung von Google Web Fonts erfolgt im
                  Interesse einer einheitlichen und ansprechenden Darstellung
                  unserer Online-Angebote. Dies stellt ein berechtigtes
                  Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Wenn
                  Ihr Browser Web Fonts nicht unterstützt, wird eine
                  Standardschrift von Ihrem Computer genutzt. Weitere
                  Informationen zu Google Web Fonts finden Sie unter
                  https://developers.google.com/fonts/faq und in der
                  Datenschutzerklärung von Google:
                  https://www.google.com/policies/privacy/.
                  <br />
                  <br /> Quelle: erecht24.de
                </h4>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default CallToActionOne;
