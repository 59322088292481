import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/header/HeaderFour";
import CallImpressum from "../../container/call-to-action/homes/CallImpressum";
import CallToAction from "../../container/call-to-action/homes/CallToActionThree";
import Footer from "../../container/footer/FooterFive";
import LayoutDefault from "../../layouts/LayoutDefault";

const Impressum = () => {
  return (
    <Fragment>
      <Helmet>
        <title>{"KAWA Druck | Impressum"}</title>
      </Helmet>
      <LayoutDefault
        className="template-color-4 template-font-1 bg_color--1"
        revealFooter={true}
      >
        <Header />
        <CallImpressum />
        <CallToAction bgColor={"#aecc36"} />
      </LayoutDefault>
      <Footer />
    </Fragment>
  );
};

export default Impressum;
