import React, { useEffect, useState } from "react";

const PageContainer = ({ children, className, revealFooter }) => {
  const [footerHeight, setFooterHeight] = useState(0);
  useEffect(() => {
    if (revealFooter) {
      const pageFooter = document.querySelector("footer");
      const footerHeight = pageFooter.offsetHeight;
      setFooterHeight(footerHeight);
    }
  }, [revealFooter]);
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }

      window.addEventListener("resize", handleResize);

      handleResize();

      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  return (
    <>
      {windowSize.width <= 350 ? (
        <>
          {" "}
          <div
            className={`zeroBottom wrapper ${
              revealFooter ? "all-wrapper" : ""
            } ${className}`}
            id="wrapper"
            style={{ marginBottom: revealFooter && footerHeight }}
          >
            {children}
          </div>
        </>
      ) : (
        <>
          {" "}
          <div
            className={` wrapper ${
              revealFooter ? "all-wrapper" : ""
            } ${className}`}
            id="wrapper"
            style={{ marginBottom: revealFooter && footerHeight }}
          >
            {children}
          </div>
        </>
      )}
    </>
  );
};

export default PageContainer;
