/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SliderSwiper from "../../components/swiper";
import sliderData from "../../data/slider/SliderArchitecture.json";
import { heroSliderConfig } from "../../helper/heroSliderConfig";

const SliderArchitecture = ({ message }) => {
  const [smallTextSize, setSmallTextSize] = useState(30);

  useEffect(() => {
    if (window.innerWidth < 1000) {
      message.length <= 50
        ? setSmallTextSize(20)
        : setSmallTextSize(20 - message.length * 0.015);
    }
  }, []);

  const settings = {
    ...heroSliderConfig,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      renderBullet: (index, className) => {
        return `<span class="${className}">0${index + 1}</span>`;
      },
    },
  };

  return (
    <div className="hero-slider hero-slider--architecture">
      <SliderSwiper settings={settings}>
        {sliderData.map((slide) => (
          <div className="swiper-slide" key={slide.id}>
            <div
              className="slide-inner slide-bg-image"
              style={{
                backgroundImage: `url(${require("../../assets/img/sliderImages/" +
                  slide.bgImg)})`,
              }}
            >
              <Container fluid className="h-100">
                <Row className="h-100 align-items-center">
                  <Col lg={{ span: 6, offset: 3 }} xl={{ span: 4, offset: 6 }}>
                    <div className="slide-content">
                      <div data-swiper-parallax="300" className="slide-title">
                        <h2
                          dangerouslySetInnerHTML={{ __html: slide.heading }}
                        />
                      </div>

                      <div data-swiper-parallax="450" className="view-more-btn">
                        <a href={`${slide.btnLink}`}>
                          {slide.btnText === "" ? (
                            <> </>
                          ) : (
                            <>
                              <span>{slide.btnText}</span>
                              <span className="btn-arrow kawaColor" />
                            </>
                          )}
                        </a>
                      </div>
                    </div>
                  </Col>
                  {slide.btnText === "" ? (
                    <>
                      {message === "" ? (
                        <>
                          <h1 className="coolBG whiteColor OGpaddingText">
                            <span className="kawaColor"> Wir</span> nehmen{" "}
                            <span className="kawaColor"> Ihnen</span> den Druck
                            ab
                            <br />
                          </h1>{" "}
                        </>
                      ) : (
                        <>
                          <h1 className="coolBG whiteColor paddingText">
                            <span className="kawaColor"> Wir</span> nehmen{" "}
                            <span className="kawaColor"> Ihnen</span> den Druck
                            ab
                            <br />
                            <span
                              className="red smallText"
                              style={{ fontSize: smallTextSize }}
                            >
                              {message}
                            </span>
                          </h1>{" "}
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </Row>
              </Container>
            </div>
          </div>
        ))}
      </SliderSwiper>
    </div>
  );
};

export default SliderArchitecture;
