import React from "react";
import { Col, Row } from "react-bootstrap";
import thumb from "../../assets/img/sliderImages/Unternehmen.jpg";

const AboutArchitecture = () => {
  return (
    <div
      id="Unternehmen"
      className="brook-story-area ptb--100 ptb-md--80 ptb-sm--60  bg_color--1"
    >
      <Row>
        <Col lg={6}>
          <div className="pl--380 pr--80 pl_lp--80 pl_lg--50 pr_lg--20 pl_md--50 pr_md--20 pl_sm--30 pr_sm--20">
            <div className="architecture-story wow move-up">
              <div className="bk-separator--25" />

              <h2 className="heading heading-h2 line-height-1-25">
                Erfolgreich in <br />{" "}
                <span className="kawaColor">3. Generation</span>
              </h2>

              <div className="bk-separator--45" />

              <h5 className="heading heading-h5 line-height-1-62">
                Ihr Partner für alle Druckdienstleistungen!
              </h5>
              <div className="bk-separator--20" />
              <p className="bk_pra">
                <span className="kawaColor ethnoFont">Kawa Druck</span> steht
                für fachliche Beratung, hochwertige Qualität, Rund-um-Service
                und Termintreue.
                <br />
                <br /> <span className="kawaColor ethnoFont">
                  Kawa Druck
                </span>{" "}
                ist die Fortführung der Geschäfte der Druckerei Karasek in
                nunmehr 3. Generation. <br />
                <br /> Hier wird
                <span className="bold"> persönlicher Kontakt</span>{" "}
                großgeschrieben, damit eine vertrauensvolle und effiziente
                Zusammenarbeit garantiert wird. <br />
                <br />
                Wir wählen den optimalen Produktionsprozess für Ihr Druckobjekt,
                selektieren die passenden Lieferanten und halten dadurch die
                Kosten gering. <br />
                <br /> <span className="kawaColor">Ihr Vorteil:</span> eine
                persönliche Ansprechperson für alle Abläufe. Wir begleiten Ihr
                Produkt vom Entwurf bis zur fertigen Auslieferung.
              </p>
              <div className="bk-separator--55" />
              <div className="signature-image">
                <h4 className="kawaColor ethnoFont bitUnder">Kawa Druck</h4>
              </div>
            </div>
          </div>
        </Col>

        <Col lg={6}>
          <div className="mt_md--40 mt_sm--40">
            <div className="architecture-story">
              <div className="thumb wow move-up">
                <img src={thumb} alt="architect" />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AboutArchitecture;
